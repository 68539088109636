<template>
	<div>
		<div>
			<home-count></home-count>
		</div>
		<div class="mt30 bg-w pd20">
			<home-ranking></home-ranking>
		</div>	
	</div>
</template>

<script>
	import homeCount from './components/Count.vue';
	import homeRanking from './components/Rank.vue';
	export default{
		components:{
			homeCount,
			homeRanking,
		},
		data(){
			return{
				
			}
		}
	}
</script>

<style>
</style>
