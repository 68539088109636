<template>
	<div class="ranking-box">
		 <div class="flex alcenter space">
			 <div class="flex alcenter">
				 <i class="iconfont iconhome_fun06 ft32" style="color:#00C657;"></i>
				 <span class="ml15 ft18 ftw600 cl-black">门店使用排行榜</span>
			 </div>
			 <div>
				 <a-radio-group v-model="type" @change="changeAct" button-style="solid">
						 <a-radio-button value="1">
						   本月
						 </a-radio-button>
						 <a-radio-button value="2">
						   本年度
						 </a-radio-button>
				 </a-radio-group>
			 </div>
		 </div>
		 <div class="mt24 wxb-table-gray">
			 <a-table rowKey="shop_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
			  :loading="loading">
				<div class="flex alcenter center" slot="rank" slot-scope="record,index">
					<img v-if="record.rank == 1" style="width: 16px; height: 26px;" src="../../../assets/image/woyaoyou_num01@3x.png" />
					<img v-if="record.rank == 2" style="width: 16px; height: 26px;" src="../../../assets/image/woyaoyou_num02@3x.png" />
					<img v-if="record.rank == 3"  style="width: 16px; height: 26px;" src="../../../assets/image/woyaoyou_num03@3x.png" />
					<span v-if="record.rank > 3">{{record.rank}}</span>
				</div>	
				
				<div class="flex alcenter center" slot="wx_face" slot-scope="record,index">
					<img :src="record" style="width: 50px; height: 50px;"/>
				</div>	
			 </a-table>
		 </div>
						 
						 
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../../common/mixin/list.js';
	
	export default{
		mixins: [listMixin],
		data(){
			return {
				loading: false,
				type:'1',
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 100,
				},
				columns: [
					{title: 'ID',dataIndex: 'shop_id',align: 'center'},
					{title: '门店编号',dataIndex: 'shop_number',align: 'center'},
					{title: '门店名称',dataIndex:'name',align: 'center',ellipsis: true},
					{title: '连锁店名',dataIndex:'abbreviation',align: 'center',ellipsis: true},
					{title: '用户注册数',dataIndex:'register',align: 'center',ellipsis: true},
					{title: '创建时间',dataIndex: 'add_time',align: 'center',ellipsis: true}
				],
				datas: [
					// {wx_face:'LE - 001',wx_name:'名尚总公司',name:'名尚造型美容Hair.sps',wx_principal_name:'1111',num:'2023-8-1',paixu:'1'}
				],
			}
		},

		methods:{
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			changeAct(){
				this.pagination.current = 1;
				this.getLists();
			},
			getLists(){
				this.loading = true;
				this.$http.api('api/api_index/ShopCharts',{
					page:this.pagination.current,
					limit:this.pagination.pageSize,
					time:this.type
				}).then(res=>{
					this.loading = false;
					this.datas = res.data.data;
					this.pagination.total = res.data.total;
					// for(var a in res.datas){
					// 	res.datas[a].rank = (parseInt(this.pagination.current) -1) * parseInt(this.pagination.pageSize) + parseInt(a) + 1;
					// 	this.datas.push(res.datas[a]);
					// }
					// console.log(this.datas);
				}).catch(res=>{
					this.loading = false;
				});
				
			}
		}
	}
</script>

<style>
	.home-rank-logo {
		width: 44px;
		height: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}
</style>